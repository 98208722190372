import { motion } from 'framer-motion'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import Image from 'next/image'
import Link from 'next/link'
import AppLogo from '../../public/assets/img/app-logo.png';
const MobileMenu = ({ setMobileMenuOpen }) => {
  const variants = {
    initial: {
      x: '150vw',
      opacity: 0,
    },
    open: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
    closed: {
      x: '150vw',
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: 'easeInOut',
      },
    },
  }
  return (
    <motion.div
      className='w-full max-w-sm h-screen z-50 absolute -top-3 -right-6 bg-blue'
      variants={variants}
      initial='initial'
      animate='open'
      exit='closed'>
      {/* <div className="flex items-center flex-shrink-0 text-white py-3 px-5 sm:hidden">
        <Link href="/"><a className='app-logo block w-full lg:max-w-[80px] max-w-[60px]'><Image src={AppLogo} alt="AppLogo" /></a></Link>
      </div> */}
      <svg
        className='h-6 w-6 absolute top-8 right-5 text-white'
        role='button'
        onClick={() => setMobileMenuOpen(false)}
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
        aria-hidden='true'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M6 18L18 6M6 6l12 12'
        />
      </svg>
      <div className='flex flex-col h-full items-center sm:pt-14 pt-8 pb-8 px-8'>
        <MobileLink text={'Home'} href={'/'} />
        <MobileLink text={'About'} href={'/about'} />
        <div className='w-full'>
          <Disclosure className="w-full">
            {({ open }) => (
              <>
                <Disclosure.Button className='flex items-center w-full text-base font-Typold py-2'>
                  <span className="text-white">Program & Services</span>
                  <ChevronDownIcon
                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-white ml-2`}
                  />
                </Disclosure.Button>
                <Link href='/eard'>
                  <Disclosure.Panel className='w-full text-white text-base font-Typold py-3 pl-5'>
                      Education, Advocacy, & Research Programs
                  </Disclosure.Panel>
                </Link>
                <Link href='/cisd'>
                  <Disclosure.Panel className='w-full text-white text-base font-Typold py-3 pl-5'>
                      Clinical and Intervention
                  </Disclosure.Panel>
                </Link>
              </>
            )}
          </Disclosure>
        </div>
        <MobileLink text={'News'} href={'/news-events'} />
        <MobileLink text={'Membership'} href={'/membership'} />
        <MobileLink text={'Careers'} href={'/careers'} />
        <MobileLink text={'Contact'} href={'/contact'} />
        <div className="sm:hidden block w-full">
          <MobileLink text={'Book Now'} href={'/booking'} />
        </div>
      </div>
    </motion.div>
  )
}

const MobileLink = ({text,href}) => {
    return (
        <Link href={href}>
         <p className='w-full text-white text-base font-Typold py-3'>{text}</p>
        </Link>

    )
}

export default MobileMenu
